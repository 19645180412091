import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <p>© Jeff Harm 2023</p>
    </footer>
  );
}
